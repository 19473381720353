import homerSimpsonMeme from '../../public/homer-simpson-meme.gif';
import Head from '../components/core/Head';
import Image from '../components/core/Image';
import { HeaderSidebarNavPageLayout } from '../components/core/Layout';
import View from '../components/core/View';

import { NextPage } from './_app';

const NotFoundPage: NextPage = () => {
  return (
    <View>
      <Head title={'TCMS'} siteTitle={'Not Found'} />
      <View>
        <View sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          404 - Page Not Found!
          <Image src={homerSimpsonMeme} alt={homerSimpsonMeme.src} />
          404 - Page Not Found!
        </View>
      </View>
    </View>
  );
};

NotFoundPage.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
NotFoundPage.authenticated = false;
NotFoundPage.routes = [];

export default NotFoundPage;
